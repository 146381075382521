import React from "react"

import CustomSoftwareIcon from "../svg/custom-software.svg"
import CloudIcon from "../svg/cloud-thin.svg"
import HeadlessCommerceIcon from "../svg/headless-commerce.svg"
import MultichannelIcon from "../svg/multichannel-marketplace.svg"

const icons = {
  "custom-software": CustomSoftwareIcon,
  "cloud-thin": CloudIcon,
  "headless-commerce": HeadlessCommerceIcon,
  "multichannel-marketplace": MultichannelIcon,
}

const Item = ({ iconName, title, description }) => {
  const Icon = icons[iconName] || "div"

  return (
    <div className="flex items-center">
      <Icon className="w-12 h-12 block flex-shrink-0 fill-current" />
      <div className="flex flex-col pl-5 md:pl-10">
        <span className="block text-lg lg:text-2xl font-bold leading-6 text-gray-800">{title}</span>
        <span className="text-base leading-6 lg:text-lg pt-2">{description}</span>
      </div>
    </div>
  )
}

function HomeInfoSection() {
  return (
    <div className="bg-gray-100 py-16 md:20">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-16 md:gap-x-16">
          <Item
            iconName="headless-commerce"
            title="Headless commerce"
            description="B2B and B2C"
          />
          <Item
            iconName="cloud-thin"
            title="Cloud Service Implementation"
            description="Google Cloud, G Suite"
          />
          <Item
            iconName="multichannel-marketplace"
            title="Multichannel Marketplace Management"
            description="Amazon, Zalando, Ebay etc"
          />
          <Item
            iconName="custom-software"
            title="Custom Software"
            description="For different business domains"
          />
        </div>
      </div>
    </div>
  )
}

export default HomeInfoSection