import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import debounce from "lodash/debounce"
import { GatsbyImage } from "gatsby-plugin-image"
import PrimaryLinkButton from "../components/PrimaryLinkButton"

function Hero() {
  const data = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      googleCloud: file(relativePath: { eq: "google-cloud-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      },
      salesforce: file(relativePath: { eq: "salesforce-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  /**
   * Handle viewport height for mobile browsers.
   */
  React.useEffect(() => {
    const handleResize = debounce(() => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }, 100)
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      document.documentElement.style.removeProperty("--vh")
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div
      className="h-screen bg-white relative transition-all duration-75 overflow-hidden"
      style={{
        height: "calc(var(--vh, 1vh) * 100)",
        minHeight: 620,
        maxHeight: 1024,
      }}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
        <GatsbyImage
          image={{
            ...data.hero.childImageSharp.gatsbyImageData,
            aspectRatio: 0,
          }}
          imgStyle={{ objectPosition: "bottom" }}
          className="w-full h-full opacity-75"
          style={{ minHeight: 620, maxHeight: 1024 }}
          alt="NMC Soft Hero"
        />
      </div>
      <div className="relative h-full flex flex-col">
        <div className="h-16 sm:h-20 flex-shrink-0"></div>
        <div className="container flex-1 flex flex-col justify-between">
          <div></div>
          <div>
            <h1 className="text-5xl md:text-6xl leading-tight font-medium text-gray-900">
              Building software for the future.
            </h1>
            <p className="max-w-3xl text-lg leading-snug md:text-2xl font-medium text-gray-700 pt-2">
              We’re a team of developers, engineers, ecommerce experts and more,
              working together to build software that powers your business.
            </p>
            <div className="pt-6">
              <PrimaryLinkButton
                to="/company"
                title="get in touch"
                size="large"
              />
            </div>
          </div>
          <div className="pb-10 flex gap-5">
            <a
              className="inline-block"
              href="https://cloud.withgoogle.com/partners/detail/?id=new-media-communications&hl=en-US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.googleCloud.childImageSharp.gatsbyImageData}
                className="h-auto w-40 md:w-48 block"
                alt="Google Cloud Partner"
              />
            </a>
            <span
              className="inline-block"
            >
              <GatsbyImage
                image={data.salesforce.childImageSharp.gatsbyImageData}
                className="h-auto w-40 md:w-48 block"
                alt="Salesforce Partner"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
