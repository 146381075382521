import React from "react"

import Seo from "../components/seo"
import GetStarted from "../components/GetStarted"
import Hero from "../components/Hero"
import ServiceSection from "../components/ServiceSection"
import HomeFeaturedProjects from "../components/HomeFeaturedProjects"
import Clients from "../components/Clients"
import HomeInfoSection from "../components/HomeInfoSection"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Hero />
    <ServiceSection />
    <HomeFeaturedProjects />
    <Clients />
    <HomeInfoSection />
    <GetStarted />
  </>
)

export default IndexPage
