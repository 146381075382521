import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Glide from "@glidejs/glide"
import FeatureProjectCard from "./FeatureProjectCard"
import SectionTitle from "./SectionTitle"

function HomeFeaturedProjects() {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fields: { collection: { eq: "projects" } }
          frontmatter: { featured: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            excerpt
            frontmatter {
              title
              slug
              industry
              features
              coverImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const [slider] = React.useState(new Glide(`.js-glide`))
  React.useEffect(() => {
    slider.mount()

    // cleanup when unmounting component
    return () => slider.destroy()
  }, [slider])

  return (
    <div className="bg-gray-100 relative py-8 md:py-12 lg:py-16">
      <div className="container relative z-10">
        <SectionTitle
          title="Our Work"
          excerpt="Developing innovative digital platforms & powerful marketing solutions in the fields of retail, construction, consumer goods, financial, manufacturing, pharmaceutical, real estate, education, recreation, tourism & travel etc."
        />
        <div className="js-glide pt-6 lg:pt-8">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {data.allMdx.edges.map(({ node }) => (
                <li className="glide__slide" key={node.frontmatter.slug}>
                  <FeatureProjectCard project={node} />
                </li>
              ))}
            </ul>
          </div>
          <div
            className="glide__bullets flex pt-5 pb-4 md:pt-10 space-x-1 sm:space-x-4 lg:space-x-6 xl:space-x-8 overflow-hidden"
            data-glide-el="controls[nav]"
          >
            {data.allMdx.edges.map(({ node }, index) => (
              <button
                className="glide__bullet project-bullet-nav"
                key={node.frontmatter.slug}
                data-glide-dir={`=${index}`}
              >
                <span className="number">{index + 1}.</span>
                <span className="title">{node.frontmatter.title}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeFeaturedProjects
